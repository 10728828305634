/* stylelint-disable selector-pseudo-class-no-unknown */
.experimentalBackdrop {
  --experimental-backdrop-background: rgba(255 255 255 / 75%);
}

.experimentalBackdrop::backdrop {
  background-color: var(--experimental-backdrop-background);
  backdrop-filter: blur(24px);
}

.experimentalBackdrop:is([data-color-mode='dark']),
:global([data-color-mode='dark'])
  .experimentalBackdrop:not([data-color-mode='light']) {
  --experimental-backdrop-background: rgba(0 0 0 / 85%);
}
