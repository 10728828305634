.truncate-2-lines {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  @media (--v-from-md) {
    display: block;
    white-space: nowrap;
  }
}
