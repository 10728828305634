.titleLarge {
  font: var(--v-font-heading-1);
}
.titleXlarge {
  font: var(--v-font-statement-2);
}

.bodyMaxWidth {
  max-width: 596px;
}

.altCta {
  align-self: flex-start;
  margin-top: var(--v-space-24);
}

@media (max-width: 767px) {
  .altCta {
    margin-top: var(--v-space-16);
  }
}

@media (min-width: 1024px) {
  .body {
    font: var(--v-font-20);
  }
  .titleXlarge {
    font: var(--v-font-statement-1);
  }
}
