@import url('@volvo-cars/css/breakpoints.css');

@media (--v-from-md) {
  .title {
    width: 100%;
    max-width: calc(var(--v-space-8) * 75);
    margin-inline: auto;
  }
}

@media (--v-from-lg) {
  .title {
    width: 100%;
    max-width: none;
    text-align: center;
  }
}

@media (--v-from-md) and (--v-until-lg) {
  .options-container {
    width: 100%;
    max-width: calc(var(--v-space-8) * 75);
    margin: 0 auto;
  }
}

.optionItemContainer {
  justify-content: center;
  width: 100%;
  padding: var(--v-space-16) var(--v-space-16) var(--v-space-16)
    var(--v-space-8);
  color: var(--v-color-foreground-primary);
  border-color: var(--v-color-ornament-primary);
}

.optionItemContainer:hover {
  color: var(--v-color-foreground-accent-blue);
  border-color: var(--v-color-foreground-accent-blue);
}

@media (--v-from-lg) {
  .optionItemContainer {
    max-width: calc(var(--v-space-8) * 41);
  }
}

@media (--v-only-md) {
  .optionItemContainer {
    justify-content: start;
    padding: var(--v-space-16) var(--v-space-24);
  }
}

.icon-container {
  flex-shrink: 0;
  height: calc(var(--v-space-8) * 9);
}

@media (--v-from-md) and (--v-until-lg) {
  .icon-container {
    margin-inline-end: calc(var(--v-space-24));
  }
}
