.card_wrapper {
  --background-color: var(--v-color-background-secondary);
  box-sizing: content-box;
  max-width: 240px;
}

.card_wrapper .card_title {
  text-decoration-line: underline;
  text-decoration-thickness: 0.125em;
  text-decoration-color: transparent;
  text-underline-offset: 0.125em;
}

.card_wrapper .card_description {
  min-height: calc(2 * 1em * var(--v-font-16-lineheight));
  text-wrap: wrap;
}

.card_wrapper:hover .card_title {
  text-decoration-color: var(--button-accent-color);
}

.badge {
  min-height: calc(1em * var(--v-font-12-lineheight));
}

.skeleton__filter {
  width: 4rem;
  height: 2rem;
  background-color: #e6e6e6; /* TODO: Change to variable once it exists */
  animation: pulse 1.5s infinite;
}

@keyframes pulse {
  0%,
  100% {
    opacity: 0.25;
  }
  50% {
    opacity: 0.6;
  }
}
