.vehicleModelContainer {
  height: fit-content;
  background-color: var(--v-color-background-secondary);

  @media (--v-until-md) {
    width: 100vw;
    margin-inline-start: -24px;
  }

  @media (--v-from-md) {
    width: 100%;
    max-width: 596px;
  }
}
