.fullWidth {
  max-width: 100%;
}
.wrapper {
  max-width: 814px;
}
.imageContainer {
  overflow: hidden;
}
.imageContainer > picture {
  transition: transform 300ms;
  transform: scale(1.001);
}
.disclaimerBlock {
  margin-top: 40px;
}
.card {
  text-decoration: none !important;
}
.card span:hover {
  color: var(--v-color-foreground-accent-blue);
}
.card picture:hover {
  scale: scale(1.08);
}
.grid {
  grid-template-columns: 1fr 1fr;
  @media (--v-from-md) {
    grid-template-columns: 1fr 1fr;
  }
  @media (--v-from-lg) {
    grid-template-columns: 1fr 1fr 1fr;
    row-gap: 40px;
  }
}
.gridForceSingleCOlumn {
  grid-template-columns: 1fr !important;
}
.isEvenAndFewCountGrid {
  @media (--v-from-lg) {
    grid-template-columns: 1fr 1fr !important;
  }
}
