.featuredDocumentsSkeleton {
  background-image: linear-gradient(
    270deg,
    var(--v-color-background-primary),
    var(--v-color-ornament-primary),
    var(--v-color-ornament-primary),
    var(--v-color-background-primary)
  );
  background-size: 400% 100%;
  opacity: 0.5;
  animation: featured-documents-bg-loading 8s ease-in-out infinite;
}

@keyframes featured-documents-bg-loading {
  0% {
    background-position: 200% 0;
  }

  100% {
    background-position: -200% 0;
  }
}

@media (prefers-reduced-motion) {
  .featuredDocumentsSkeleton {
    background-color: var(--v-color-ornament-primary);
    background-image: none;
    opacity: 1;
    animation: none;
  }
}

.skeletonItemDescription {
  height: 72px;
}
