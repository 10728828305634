.footer-slot {
  position: sticky;
  top: 0;
  bottom: calc(-1 * (var(--v-space-48)));
  z-index: var(--v-index-overlay);
  margin-inline: calc(-1 * (var(--v-space-24)));
  margin-bottom: calc(-1 * (var(--v-space-48)));
  opacity: 0;
  animation: slide-up-fade-in 0.25s ease forwards;

  @media (--v-from-md) {
    margin-inline: calc(-1 * (var(--v-space-48)));
  }
}

.closing {
  pointer-events: none;
  animation: slide-down-fade-out 0.25s ease;
}

@keyframes slide-down-fade-out {
  from {
    opacity: 1;
    transform: translateY(0);
  }

  to {
    opacity: 0;
    transform: translateY(100%);
  }
}

@keyframes slide-up-fade-in {
  from {
    opacity: 0;
    transform: translateY(100%);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}
