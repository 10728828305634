@import url('@volvo-cars/css/breakpoints.css');

.inner-container {
  flex-direction: column;
  width: 100%;
  height: 100%;

  @media (--v-from-lg) {
    flex-direction: row;
    max-width: 1022px;
  }
}

.image-container {
  overflow: hidden;
}

.flex-shrink {
  flex: 0 1 44%;
}

.usps-container > :last-child {
  margin-bottom: 0;
}

.usps-item-container {
  margin-bottom: var(--v-space-32);
}

@media (--v-from-lg) {
  .vertical-padding {
    padding-top: var(--v-space-48);
    padding-bottom: var(--v-space-48);
  }

  .horizontal-padding {
    padding-inline-start: calc(var(--v-space-8) * 13);
    padding-inline-end: calc(var(--v-space-8) * 13);
  }

  .usps-container {
    padding: 0px;
    margin: auto 0px;
  }
}
