.markdown strong {
  font-weight: var(--v-font-emphasis-weight);
}

.markdown table {
  width: 100%;
  table-layout: fixed;
  border-spacing: 1px;
  background-color: var(--v-color-foreground-secondary);
}

.markdown :is(thead, tbody) {
  background-color: var(--v-color-background-secondary);
}

.markdown :is(th, td) {
  padding: var(--v-space-4);
}

.markdown blockquote {
  padding: var(--v-space-16);
  background-color: var(--background-color);
  border-color: var(--v-color-foreground-secondary);
  border-inline-start-width: 2px;
}

.markdown ul,
.markdown ol {
  padding-inline-start: var(--v-space-24);
}
