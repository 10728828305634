@import url('@volvo-cars/css/breakpoints.css');

@media (--v-from-lg) {
  .content {
    max-width: 350px;
  }
}
@media (--v-from-xl) {
  .content {
    max-width: 500px;
  }
}
