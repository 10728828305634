.disclaimer-max-width {
  max-width: 568px;
}

.disclaimer-container {
  width: calc(100% - var(--v-space-48));
  margin: 0 auto;

  a[href] {
    color: var(--v-color-foreground-accent-blue);
    text-decoration: underline;
  }
}
