.cards-grid :first-child {
  grid-column: 1 / -1;
}

/*
 * accent-blue-subtle is not a proper utility class for background usage. Its
 * usage here has been okayed as the package and its UI component are exploring
 * and experimenting, particularly towards the PDP 2.0 pages.
 */
.promo-container {
  background-color: var(--v-color-state-accent-blue-subtle);
}
