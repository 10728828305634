.description {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
}
.title {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
}

.card:hover .cardLink {
  text-decoration-line: underline;
  text-decoration-thickness: 0.125em;
  text-underline-offset: 0.125em;
  text-decoration-skip-ink: none;
}
