.slide-out {
  animation: slide-left 0.3s ease-out, fade-in 0.3s ease-out;
}

.slide-in {
  animation: slide-right 0.3s ease-out, fade-in 0.3s ease-out;
}

.hidden-page {
  display: none;
}

.animating {
  overflow-x: hidden;
}

.page-wrapper {
  height: max-content;
  overflow-x: hidden;
  overflow-y: visible;
}

@keyframes slide-left {
  from {
    transform: translateX(-30%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes slide-right {
  from {
    transform: translateX(30%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
