.dialog {
  position: absolute;
  inset-inline: 0;
  z-index: 1;
  margin: var(--v-space-24);
  background: var(--v-color-background-primary);
}

.font {
  margin-bottom: var(--v-space-8);
  font-size: var(--v-font-12);

  @media (--v-from-md) {
    font-size: var(--v-font-16);
  }
}

.title {
  font-weight: var(--v-font-emphasis-weight);
}

.link {
  position: relative;
  display: inline;
  text-decoration: underline;
}

.alert {
  border-inline-start: 2px solid var(--v-color-foreground-feedback-red);
}
.default {
  border-inline-start: 2px solid var(--v-color-foreground-accent-blue);
}
.success {
  border-inline-start: 2px solid var(--v-color-foreground-feedback-green);
}
