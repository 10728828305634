.scale-width {
  height: auto;
  padding: 0;
  margin: auto;
  overflow: unset;
}

.scale-height {
  position: relative;
  width: auto;
  height: 100%;
  max-height: calc(
    100dvh +
    -1 *
    min(2rem, 20vh) +
    -1 *
    (40px + var(--v-space-32))
  );
  margin: auto;
}
