.content_wrapper {
  @media (--v-from-md) {
    padding-inline: 12px;
  }
  @media (--v-from-lg) {
    flex: 1 1 50%;
  }
}

.main_container {
  @media (--v-until-md) {
    width: 100%;
    max-width: 610px;
    margin: 0 auto;
  }
}

.media {
  @media (--v-from-md) {
    margin: 0 12px;
  }
  @media (--v-from-lg) {
    flex: 1 1 50%;
  }
  width: 100%;
  height: 100%;
}

.quote {
  width: 0;
  height: 0;
  font-size: 200;
  color: #d8edf2;
  user-select: none;
  transform: translateX(-23px) translateY(-59px);
}

.cta {
  @media (--v-until-md) {
    align-self: flex-end;
  }
}

.ctaLeft {
  @media (--v-until-md) {
    align-self: flex-start;
  }
}

.ctaCenetred {
  @media (--v-until-md) {
    align-self: center;
  }
}
