.tooltip {
  position: relative;
  display: inline-block;
}

.tooltipContent {
  position: absolute;
  inset-inline-start: 50%;
  z-index: 4;
  width: 300px;
  padding: var(--v-space-8);
  visibility: hidden;
  border-radius: var(--v-radius-sm);
  transform: translate3d(-50%, 12px, 0);
}

.arrow {
  position: absolute;
  bottom: 100%;
  inset-inline-start: 50%;
  border-color: transparent transparent var(--v-color-background-primary)
    transparent;
  border-style: solid;
  border-width: 8px;
  transform: translateX(-50%);
}

.tooltipContentAbove {
  transform: translate3d(-50%, calc(-100% - 12px), 0);
}

.arrowAbove {
  top: 100%;
  bottom: unset;
  border-color: var(--v-color-background-primary) transparent transparent;
}

.image {
  border-radius: var(--v-radius-sm);
}

@media (--v-from-lg) {
  .tooltip:hover .tooltipContent {
    visibility: visible;
  }
}
