.heading {
  font: var(--v-font-heading-2);

  @media (--v-from-lg) {
    font: var(--v-font-statement-3);
    font-size: var(--v-font-statement-3-size-min);
  }
}

.largeHeading {
  font: var(--v-font-heading-2);
  font-size: var(--v-font-heading-2-size-max);

  @media (--v-from-lg) {
    font: var(--v-font-statement-3);
  }
}

.textContainer {
  gap: var(--v-space-16);

  @media (--v-from-lg) {
    gap: var(--v-space-l);
  }
}
