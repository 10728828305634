.vehiclePicker {
  width: calc(100% + 4px);
  margin-inline-start: -4px;
}

.inOverlay {
  max-width: 519px;

  @media (--v-until-lg) {
    max-width: 400px;
  }
}
